import Grid from "@material-ui/core/Grid"
import Layout from "src/components/layout"
import Navbar from "src/components/navbar"
import React from "react"
import SEO from "src/components/seo"
import TeamMember from "src/components/team-member"
import _ from "lodash"
import aboutPage from "content/data/static-pages/about.json"
import cx from "classnames"
import map from "lodash/map"
import navbarStyles from "src/styles/components/navbar.module.scss"
import sectionStyles from "src/styles/components/section.module.scss"
import styles from "src/styles/pages/about.module.scss"
import useTeam from "src/static_queries/useTeam"

export default function AboutPage() {
  const { de: page } = aboutPage
  let team = useTeam()
  team = _.map(team, "frontmatter")
  team = team.filter(member => member.is_active === true)

  return (
    <Layout className={styles.root}>
      <SEO
        title={page.about_title}
        description={page.title}
        image={page.jumbo_image}
      />
      <Navbar className={navbarStyles.mint} />
      <div
        className={styles.jumboImage}
        style={{
          backgroundImage: `url("${page.jumbo_image}")`,
          backgroundSize: "cover",
        }}
      />
      <section className={cx(sectionStyles.root, styles.titleSection)}>
        <h1>{page.title}</h1>
      </section>
      <section className={cx(sectionStyles.root, styles.aboutTextSection)}>
        <h4>{page.about_title}</h4>
        <Grid container>
          {map(page.about_text, text => (
            <Grid item xs={12} md className={styles.textColumn}>
              <p>{text}</p>
            </Grid>
          ))}
        </Grid>
      </section>
      <section
        className={cx(
          sectionStyles.root,
          sectionStyles.colored,
          styles.teamSection
        )}
      >
        <div className={cx(sectionStyles.title)}>
          <h4>{page.vision_title}</h4>
        </div>
        <p className={cx(sectionStyles.description)}>{page.vision_text}</p>
        <div className={cx(sectionStyles.title)}>
          <h4>{page.team_title}</h4>
        </div>
        <Grid container>
          {map(team, member => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <TeamMember member={member} />
            </Grid>
          ))}
        </Grid>
      </section>
      <section className={cx(sectionStyles.root, styles.footerSection)}>
        <h4>{page.footer_text}</h4>
        {page.cta_link && (
          <a target="_blank" rel="noopener noreferrer" href={page.cta_link}>
            <button>{page.cta_text}</button>
          </a>
        )}
      </section>
    </Layout>
  )
}
